import React, { useEffect, useState, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import GridItem from "components/Grid/GridItem.js";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Select from "react-select";
import "react-google-places-autocomplete/dist/index.min.css";
import GridContainer from "components/Grid/GridContainer.js";
import EmojiPicker from "emoji-picker-react";
import IconButton from "@material-ui/core/IconButton";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import debounce from "@material-ui/core/utils/debounce"; // Correct import

export const API_URL = process.env.REACT_APP_URL + "/api";

export default function SendNotification(props) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [mSName, setMSName] = useState("");
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState(""); 
  const [showPicker, setShowPicker] = useState(false); 
  const [searchQuery, setSearchQuery] = useState("");

  const fetchUsers = useCallback(
    debounce(async (query) => {
      if (query.trim().length > 0) {
        try {
          const { data } = await axios.get(`${API_URL}/user/find-user?q=${query}`);
          setUsers(data.users);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      } else {
        setUsers([]);
      }
    }, 500), 
    []
  );

  useEffect(() => {
    fetchUsers(searchQuery);
    return () => fetchUsers.clear(); 
  }, [searchQuery, fetchUsers]);

  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    props.handleSendNotify(
      selectedOptions.map((i) => i.value),
      "selectMultipleUser"
    );
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prev) => `${prev + emojiObject.emoji}`);
    let e = { target: { value: message } };
    props.handleSendNotify(e, "message")
    setShowPicker(false);
  };

  const user_type = [
    { value: "", label: "Please Select user" },
    { value: "allUser", label: "All User" },
    { value: "selectedUser", label: "Selected User" },
  ];

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            onChange={(e) => props.handleSendNotify(e, "title")}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <TextField
            margin="dense"
            id="selectUser"
            label="Select User"
            select
            type="text"
            fullWidth
            onChange={(e) => {
              setMSName(e.target.value);
              props.handleSendNotify(e, "selectUser");
            }}
          >
            {user_type.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </GridItem>
      </GridContainer>

      {mSName === "selectedUser" && (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Select
              id="selectMultipleUser"
              isMulti
              name="selectMultipleUser"
              options={users?.map((i) => ({ label: `${i.username}   (${i?.email})`, value: i.id }))}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select User (search by name or mail)"
              value={selectedOptions}
              onChange={handleChange}
              onInputChange={(value) => setSearchQuery(value)}
            />
          </GridItem>
        </GridContainer>
      )}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ position: "relative" }}>
          <TextField
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
              props.handleSendNotify(e, "message");
            }}
          />
          <IconButton
            onClick={() => setShowPicker((prev) => !prev)}
            style={{ position: "absolute", right: 10, top: 10 }}
          >
            <InsertEmoticonIcon />
          </IconButton>
          {showPicker && (
            <div style={{ position: "absolute", right: 0, zIndex: 100 }}>
              <EmojiPicker onEmojiClick={handleEmojiClick} />
            </div>
          )}
        </GridItem>
      </GridContainer>

      <DialogActions>
        {/* <Button onClick={(e) => props.backPage(e)} variant="contained">
          Cancel
        </Button> */}
        <Button
          onClick={(e) => props.submitData(e, "add")}
          color="primary"
          variant="contained"
        >
          Send Notification
        </Button>
      </DialogActions>
    </div>
  );
}
