import React, { Component } from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import AWS from "aws-sdk";
import {useNavigate} from 'react-router-dom'

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { awsConfigNew } from "../../config.js";

import {
  personList,
  blockPerson,
  addPerson,
  editPerson,
  viewPerson,
  searchApi,
} from "../../actions/user.js";
import {
  getAllWarrantyLevel,
  addWarrantyLevel,
  deleteWarrantyLevel,
  updateWarrantyLevel,
  getWarrantyLevelById,
  searchWarrantyLevel,
} from "../../actions/device.js";
import { toaster } from "../../helper/Toaster.jsx";
import AddEditPage from "./AddEditPage.jsx";
import Tooltip from "@material-ui/core/Tooltip";

import { API_URL } from "../../config.js";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition,
} from "react-toasts";
import moment from "moment";
import ViewWarrantyPage from "./ViewWarrantyPage.jsx";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
let detail_flag = false;
let block_flag = false;
let delete_flag = false;
let add_flag = false;
let edit_flag = false;
let s3 = new AWS.S3(awsConfigNew);

export class WarrantyPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      searchData: [],
      detail_page: false,
      add_page: false,
      edit_page: false,
      selected_ongoing: "",
      warranty_data: {
        levels: "",
        isOngoing: "",
        price: "",
        deductible_payment_event_1: "",
        deductible_payment_event_2: "",
        id: "",
      },
      page: 0,
      count: 0,
      rowsPerPage: 10,
    };
  }
  componentDidMount() {
    this.props.getAllWarrantyLevel();
    if(this.state.selected_ongoing){
    this.props.searchWarrantyLevel(this.state.selected_ongoing);
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const {
      warranty_level_list,
      warranty_data,
      add_response,
      edit_response,
      delete_response,
      search_list
    } = newProps;

    // if ("add_on_list" in newProps) {
    //   let { add_on_list } = newProps;
    const data1 = [];
    let i = 1;
    if (warranty_level_list && warranty_level_list.status === 200) {
      let count = warranty_level_list.count;
      const prob_data = warranty_level_list.data;
      console.log(prob_data, "data13");
      // let count = prob_data.length;
      prob_data.map((data) => {
        let arr = { view: data, edit: data, delete: data };
        data1.push([
          i++,
          data.levels,
          data.price,
          data.deductible_payment_event_1,
          data.deductible_payment_event_2,
          // data.isOngoing,
          arr,
        ]);
      });
      this.setState({
        data: data1,
        count,
      });
    }
    let j = 1;
    const search = []
    if (search_list && search_list.status === 200) {
      let count = search_list.count;
      const prob_data = search_list.data;
      prob_data.map((data) => {
        let arr = { view: data, edit: data, delete: data };
        search.push([
          j++,
          data.levels,
          data.price,
          data.deductible_payment_event_1,
          data.deductible_payment_event_2,
          // data.isOngoing,
          arr,
        ]);
      });
      this.setState({
        searchData: search,
        count,
      });
    }
    if (warranty_data && warranty_data.status === 200) {
      this.setState({
        warranty_data: warranty_data.data,
        // selected_person: person_data,
      });
    } else if (warranty_data && warranty_data.status === 500) {
      toaster("error", warranty_data.message);
    }
    if (add_response && add_response.status === 200 && add_flag) {
      this.props.searchWarrantyLevel(this.state.selected_ongoing);
      toaster("success", add_response.message);
      this.setState({
        detail_page: false,
        add_page: false,
        edit_page: false,
      });
      this.props.getAllWarrantyLevel();
      add_flag = false;
    } else if (add_response && add_response.status === 500 && add_flag) {
      toaster("error", add_response.message);
      add_flag = false;
    }
    if (edit_response && edit_response.status === 200 && edit_flag) {
      this.props.searchWarrantyLevel(this.state.selected_ongoing);
      toaster("success", edit_response.message);
      this.setState({
        detail_page: false,
        add_page: false,
        edit_page: false,
      });
      this.props.getAllWarrantyLevel();
      edit_flag = false;
    } else if (edit_response && edit_response.status === 500 && edit_flag) {
      toaster("error", edit_response.message);
      edit_flag = false;
    }
    if (delete_response && delete_response.status === 200 && delete_flag) {
      this.props.searchWarrantyLevel(this.state.selected_ongoing);
      toaster("success", delete_response.message);
      this.setState({
        detail_page: false,
        add_page: false,
        edit_page: false,
      });
      this.props.getAllWarrantyLevel();
      delete_flag = false;
    } else if (
      delete_response &&
      delete_response.status === 500 &&
      delete_flag
    ) {
      toaster("error", delete_response.message);
      delete_flag = false;
    }
  }
  backPage = () => {
    this.setState({
      detail_page: false,
      add_page: false,
      edit_page: false,
      warranty_data: {
        levels: "",
        deductible_payment_event_1: "",
        deductible_payment_event_2: "",
        isOngoing: "",
        price: "",
        id: "",
      },
    });
  };

  handleEditChange = (e, setname) => {
    var warranty_data = this.state.warranty_data;
    // if (setname === "levels") {
    //   this.setState({
    //     levels: e.target.value,
    //   });
    // } else
    if (setname === "price" && /^[0-9]{0,6}$/.test(e.target.value) === false) {
      return;
    } else if (
      setname === "deductible_payment_event_1" &&
      /^[0-9]{0,6}$/.test(e.target.value) === false
    ) {
      return;
    } else if (
      setname === "deductible_payment_event_2" &&
      /^[0-9]{0,6}$/.test(e.target.value) === false
    ) {
      return;
    }
    //  else if (
    //   setname === "isOngoing" &&
    //   this.setState({
    //     isOngoing: e.target.value,
    //   })
    // )
    warranty_data[setname] = e.target.value;
    this.setState({ warranty_data });

    console.log(warranty_data, "warranty_data");
  };
  handleActions = (e, action, item) => {
    console.log(action,"action")
    if (action === "get") {
      this.setState({ [item]: e.target.value });
      this.props.searchWarrantyLevel(e.target.value);
    }
  };
  handleChange = (setname, data) => {
    // if (setname === "get") {
    //   this.setState({ [item]: e.target.value });
    //   this.props.brandList(e.target.value);
    // }
    if (setname === "view") {
      this.setState({ detail_page: true });
      let params = {
        id: data.id,
      };
      this.props.getWarrantyLevelById(params);
      detail_flag = true;
    } else if (setname === "delete") {
      if (
        window.confirm("Are you sure you want to delete this warranty level?")
      ) {
        let params = {
          id: data.id,
        };
        this.props.deleteWarrantyLevel(params);
        delete_flag = true;
        this.props.getAllWarrantyLevel();
      }
    } else if (setname === "add") {
      this.setState({
        add_page: true,
        warranty_data: {
          levels: "",
          deductible_payment_event_1: "",
          deductible_payment_event_2: "",
          isOngoing: "",
          price: "",
        },
      });
    } else if (setname === "edit") {
      this.setState({
        edit_page: true,
        warranty_data: {
          levels: data.levels,
          deductible_payment_event_1: data.deductible_payment_event_1,
          deductible_payment_event_2: data.deductible_payment_event_2,
          isOngoing: data.isOngoing,
          price: data.price,
          id: data.id,
        },
      });
    }
  };
  submitData = async(e, setname) => {
    const { warranty_data } = this.state;

    const {
      levels,
      price,
      id,
      deductible_payment_event_1,
      deductible_payment_event_2,
      isOngoing,
    } = warranty_data;

    if (
      (levels === "" || deductible_payment_event_1 === "" || price === "",
      deductible_payment_event_2 === "",
      isOngoing === "")
    ) {
      toaster("error", "Please select all the fields");
    } else {
      let params = {
        levels: levels,
        deductible_payment_event_1: deductible_payment_event_1,
        deductible_payment_event_2: deductible_payment_event_2,
        isOngoing: isOngoing,
        price: price,
        id: id,
      };
      if (setname === "add") {
        await this.props.addWarrantyLevel(params);
        await this.props.getAllWarrantyLevel();
      } else {
        await this.props.updateWarrantyLevel(params);
        await this.props.getAllWarrantyLevel();
        window.location.href = '/warranty';
      }
      add_flag = true;
    }
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 });
  };

  render() {
    const {
      data,
      searchData,
      detail_page,
      add_page,
      edit_page,
      count,
      page,
      rowsPerPage,
      selected_ongoing,
    } = this.state;

    let title = "";
    if (detail_page) {
      title = "View Warranty Level";
    } else {
      if (add_page) {
        title = "Add Warranty Level";
      } else {
        title = "Edit Warranty Level";
      }
    }

    return (
      <GridContainer>
        <ToastsContainer
          store={ToastsStore}
          position={ToastsContainerPosition.TOP_RIGHT}
        />

        <GridItem xs={12} sm={12} md={12}>
          {!detail_page && (
            <div className="parent_search_export">
              {/* <div className="right">
                <TextField
                  id="standard-search"
                  label="Search field"
                  type="search"
                  // onChange={(e) => this.handleSearchChange(e)}
                />
              </div> */}
              <FormControl>
                <InputLabel id="demo-simple-select-label">Search</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selected_ongoing}
                  onChange={e =>
                    this.handleActions(e, "get", "selected_ongoing")
                  }
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value={"true"}>Ongoing</MenuItem>
                  <MenuItem value={"false"}>Not Ongoing</MenuItem>
                </Select>
              </FormControl>
            </div>
          )} 
          <Card>
            {!detail_page && !add_page && !edit_page ? (
              <CardHeader color="primary" className="header_platform">
                <Typography variant="h5">
                  Warranty Level
                  <Tooltip title="Add" className="icon_boundry">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      onClick={(e) => this.handleChange("add")}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </CardHeader>
            ) : (
              <CardHeader color="primary">
                <Typography variant="h5" className="header_platform">
                  {title}
                  <Tooltip title="Back" className="icon_boundry">
                    <IconButton
                      edge="end"
                      aria-label="back"
                      onClick={(e) => this.backPage(e)}
                    >
                      <SubdirectoryArrowLeftIcon />
                    </IconButton>
                  </Tooltip>
                  {/* {(edit_page) && <Tooltip title="Edit" className='icon_boundry'>
                      <IconButton
                        edge="end"
                        aria-label="back"
                        onClick={e => this.handleChange("edit")}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>} */}
                </Typography>
              </CardHeader>
            )}
            <CardBody>
              {!detail_page && !add_page && !edit_page && (
                <Table
                  customClass="table_class_width"
                  tableHeaderColor="primary"
                  tableHead={[
                    "S.No.",
                    "Level",
                    "Price ($)",
                    "Deductible Payment Event 1 (%)",
                    "Deductible Payment Event 2 (%)",
                    // "Ongoing",
                    "Actions",
                  ]}
                  tableData={!selected_ongoing? data : searchData}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={count}
                  handleChange={this.handleChange}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              )}
              {detail_page && (!add_page || !edit_page) && (
                <ViewWarrantyPage
                  state={this.state}
                  handleEditChange={this.handleEditChange}
                  backPage={this.backPage}
                />
              )}
              {!detail_page && (add_page || edit_page) && (
                <AddEditPage
                  state={this.state}
                  handleEditChange={this.handleEditChange}
                  backPage={this.backPage}
                  submitData={this.submitData}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    warranty_level_list: store.device.warranty_level_list,
    warranty_data: store.device.warranty_data,
    add_response: store.device.add_response,
    edit_response: store.device.edit_response,
    delete_response: store.device.delete_response,
    search_list: store.device.search_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllWarrantyLevel: () => dispatch(getAllWarrantyLevel()),
    getWarrantyLevelById: (params) => dispatch(getWarrantyLevelById(params)),
    updateWarrantyLevel: (params) => dispatch(updateWarrantyLevel(params)),
    addWarrantyLevel: (params) => dispatch(addWarrantyLevel(params)),
    deleteWarrantyLevel: (params) => dispatch(deleteWarrantyLevel(params)),
    searchWarrantyLevel: params => dispatch(searchWarrantyLevel(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyPage);
